<template>
  <el-card class="info_card">
    <div slot="header">
      <el-row type="flex" justify="space-between"
        ><el-col :span="10"><span>Papers on DECAR</span></el-col>
        <el-col :span="10">
          <el-input
            placeholder="Search a paper"
            v-model="keyWord"
            class="input-with-select"
            @keyup.enter.native="getInfo"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getInfo"
            ></el-button>
          </el-input> </el-col
      ></el-row>
    </div>
  <div>
    <el-table :data="infoList" style="width: 100%" v-loading = "loading">
      <el-table-column prop="title" label="Title"> </el-table-column>
      <el-table-column prop="source" label="Source" width="80"> </el-table-column>
      <el-table-column prop="article_link" label="Link" width="280">
      </el-table-column>
      <el-table-column prop="publish_time" width="165" label="Publication time"
        ><template slot-scope="scope">
          <el-tag type="success">{{
            timestampToTime(scope.row.publish_time)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" width="165" label="Time created"
        ><template slot-scope="scope">
          <el-tag type="warning">{{
            timestampToTime(scope.row.create_time)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="update_time" width="165" label="Last updated"
        ><template slot-scope="scope">
          <el-tag type="warning">{{
            timestampToTime(scope.row.update_time)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Operatiion" width="100">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-view"
            @click="handleShowDetail(scope.$index, scope.row)"
            >View</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <ArticleShowDetail ref="ArticleShowDetail" />
  </div>
</el-card>
</template>

<script>
import ArticleShowDetail from './ArticleShowDetail/index.vue'
export default {
  name: "ArticleSelect",
  components:{ArticleShowDetail},
  data() {
    return {
      loading:true,
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      infoList: [],
      //模糊搜索关键词
      keyWord:"",
      //文章类型业务主键
      articleTypeUnCode: "",
    };
  },
  methods: {
    getInfo() {
      this.$store
        .dispatch("article/select", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
          keyWord:this.keyWord,
          articleTypeUnCode: this.articleTypeUnCode,
        })
        .then((res) => {
          var { data } = res;
          this.infoList = data.info_list;
          this.total = data.total;
          this.loading = false
        }).catch((error) =>{
          console.log(error);
          this.loading = false
        });
    },
    getArticleInfo(data){
      this.articleTypeUnCode = data;
      this.getInfo()
    },
    handleShowDetail(index, row) {
      //获取本地最新文章unCode对象
      var articleUnCode = this.$store.state.latestArticleUncode;
      //如果查看的事最新的文章，则取消掉新文章标识
      if(articleUnCode != undefined && articleUnCode.un_code === row.un_code){
        //将该文章设置为不是最新的
        articleUnCode.flag = 0
        this.$store.commit("setLatestArticleUncode", articleUnCode);
      }
      //打开文章详情组件
      this.$refs.ArticleShowDetail.handleOpen(row)
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>

<style>
</style>