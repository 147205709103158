<template>
  <div>
    <el-dialog
      title="View paper"
      :visible.sync="dialogVisible"
      width="90rem"
      :close-on-click-modal="false"
    >
      <div v-html="content"></div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose()" icon="el-icon-circle-close"
          >Close</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    name:"ArticleShowDetail",
    data(){
        return{
            dialogVisible:false,
            content:""
        }
    },
    methods:{
        handleOpen(data){
            this.dialogVisible = true
            this.content = data.content
        },
        handleClose(){
            this.dialogVisible = false
        }
    }
}
</script>

<style>

</style>